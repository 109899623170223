<template>
  <div>
    <draggable
      v-bind="dragOptions"
      v-model="processors.L"
      @input="emitter"
    >
      <v-list-group
        v-for="(processorL, processorLIndex) in processors && processors.L"
        :key="processorLIndex"
        :value="true"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Group {{ processorLIndex + 1 }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-slot:appendIcon>
          <v-menu
            bottom
            offset-y
            :close-on-content-click="closeOnContentClick"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
              >mdi-menu</v-icon>
            </template>
            <v-list
              dense
            >
              <v-list-group
                sub-group
                :value="true"
                @click="closeOnContentClick = false"
              >
                <template v-slot:activator>
                  <v-list-item-title>Add New</v-list-item-title>
                  <v-list-item-icon>
                  <v-icon>mdi-plus-circle</v-icon>
                </v-list-item-icon>
                </template>
                  <v-list-item
                    class="ml-4"
                    v-for="(item, i) in endpointEnum.tasks"
                    :key="i"
                    link
                    @click="closeOnContentClick = true, addNewTask(processorLIndex, item)"
                  >
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item>
              </v-list-group>
              <v-list-item
                link
                @click="confirmDialog = {
                  isActive: true,
                  type: 'group',
                  groupIndex: processorLIndex,
                  taskIndex: -1,
                  name: `Group ${processorLIndex + 1}`
                }, closeOnContentClick = true"
              >
                <v-list-item-title>Delete</v-list-item-title>
                <v-list-item-icon>
                  <v-icon>mdi-delete-circle</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <draggable
          v-bind="dragOptions2"
          v-model="processorL.L"
          @input="emitter"
        >
          <template v-for="(processorLL, processorLLIndex) in processorL.L">
            <template v-if="processorLL.M.optionTemplate && processorLL.M.optionTemplate.M && processorLL.M.optionTemplate.M.processors">
              <v-list-group
                :key="processorLLIndex"
                :value="true"
                sub-group
              >
                <template v-slot:activator>
                  <v-list-item-content @click.stop="$emit('taskSelect', processorLL.M), setTaskSelect(groupLevel, processorLIndex, processorLLIndex)">
                    <v-list-item-title v-text="processorLL.M.name.S.split(/\./)[1]" />
                  </v-list-item-content>
                </template>
                <template v-slot:appendIcon>
                  <v-menu
                    bottom
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >mdi-dots-vertical</v-icon>
                    </template>
                    <v-list
                      dense
                    >
                      <v-list-item
                        link
                        @click="addNewTaskProcessor(processorLIndex, processorLLIndex)"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-plus-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Add Group</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        @click="confirmDialog = {
                          isActive: true,
                          type: 'task',
                          groupIndex: processorLIndex,
                          taskIndex: processorLLIndex,
                          name: processorLL.M.name.S
                        }"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-delete-circle</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <processor-list-menu class="ml-4"
                  v-model="processorLL.M.optionTemplate.M.processors"
                  :groupLevel="groupLevel + 1"
                  :taskSelectIndexObj="taskSelectIndexObj"
                  @updateTaskSelect="$emit('updateTaskSelect', $event)"
                  @taskSelect="handleTaskSelect"
                />
              </v-list-group>
            </template>
            <template v-else>
              <v-list-item
                :class="groupLevel === taskSelectIndexObj.groupLevel
                && processorLIndex === taskSelectIndexObj.groupIndex
                && processorLLIndex === taskSelectIndexObj.taskIndex ? 'listactive' : null"
                :key="processorLLIndex"
                dense
                @click="$emit('taskSelect', processorLL.M), setTaskSelect(groupLevel, processorLIndex, processorLLIndex)"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-content @click="active ? $event.stopPropagation() : null">
                    <v-list-item-title class="ml-4" v-text="processorLL.M.name.S.split(/\./)[1]" />
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon @click.stop="() => {
                      confirmDialog = {
                        isActive: true,
                        type: 'task',
                        groupIndex: processorLIndex,
                        taskIndex: processorLLIndex,
                        name: processorLL.M.name.S
                      }
                    }">mdi-delete-circle</v-icon>
                  </v-list-item-icon>
                </template>

              </v-list-item>
            </template>
          </template>
        </draggable>

      </v-list-group>
    </draggable>
    <confirm-dialog
      v-model="confirmDialog.isActive"
      type="delete"
      :title="confirmDialog.name"
      @confirm="confirmDeleteDialog()"
    />
  </div>

</template>

<script>

import endpointTemplateForm from '@/template/endpointTemplate.json'
import draggable from 'vuedraggable'

export default {
  name: 'ProcessorListMenu',
  model: {
    prop: 'processors'
  },
  components: {
    ConfirmDialog: () => import('@/components/ConfirmDialog.vue'),
    draggable
  },
  props: {
    processors: {
      type: Object,
      default: function () {
        return {}
      },
      required: true
    },
    taskSelectIndexObj: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    groupLevel: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data: () => ({
    confirmDialog: {
      isActive: false,
      type: 'task',
      groupIndex: -1,
      taskIndex: -1,
      name: null
    },
    closeOnContentClick: false
    // taskSelectIndexObj: {
    //   groupLevel: 0,
    //   groupIndex: 0,
    //   taskIndex: null
    // }
  }),
  computed: {
    endpointEnum: function () {
      return endpointTemplateForm.enum
    },
    dragOptions: function () {
      return {
        animation: 200,
        group: 'group',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    dragOptions2: function () {
      return {
        animation: 200,
        group: 'processor',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    addNewTask: function (processorLIndex, itemName) {
      // console.log(processorLIndex, itemName)
      let task = JSON.parse(JSON.stringify(endpointTemplateForm.template.taskList))
      let processor = JSON.parse(JSON.stringify(endpointTemplateForm.template.processorTemplate))
      processor.name.S = itemName
      processor.optionTemplate = task[itemName.split(/\./g).pop()]
      this.processors.L[processorLIndex].L.push({ M: processor })
      this.setTaskSelect(this.groupLevel, processorLIndex, this.processors.L[processorLIndex].L.length - 1)
      this.$emit('taskSelect', processor)
      // this.$emit('update', this.processors.processors)
    },
    addNewTaskProcessor: function (processorLIndex, processorLLIndex) {
      // console.log(processorLIndex, processorLLIndex)
      if (this.processors.L[processorLIndex].L[processorLLIndex].M.optionTemplate.M.processors.L) {
        this.processors.L[processorLIndex].L[processorLLIndex].M.optionTemplate.M.processors.L.push({ L: [] })
      } else {
        this.processors.L[processorLIndex].L[processorLLIndex].M.optionTemplate.M.processors = {
          L: [{ L: [] }]
        }
      }
      // this.$emit('update', this.processors)
    },
    deleteGroup: function (processorLIndex) {
      this.processors.L = this.processors.L.filter((item, itemIndex) => itemIndex !== processorLIndex)
      // this.$emit('update', this.processors)
    },
    deleteTask: function (processorLIndex, processorLLIndex) {
      this.processors.L[processorLIndex].L = this.processors.L[processorLIndex].L.filter((item, itemIndex) => itemIndex !== processorLLIndex)
      // this.$emit('update', this.processors)
      if (this.processors.L[processorLIndex].L.length >= processorLLIndex + 1) {
        this.$emit('taskSelect', this.processors.L[processorLIndex].L[processorLLIndex].M)
        this.setTaskSelect(this.groupLevel, processorLIndex, processorLLIndex)
      } else {
        if (this.processors.L[processorLIndex].L.length > 0) {
          this.$emit('taskSelect', this.processors.L[processorLIndex].L[processorLLIndex - 1].M)
          this.setTaskSelect(this.groupLevel, processorLIndex, processorLLIndex - 1)
        } else {
          this.$emit('taskSelect', null)
        }
      }
    },
    confirmDeleteDialog: function () {
      if (this.confirmDialog.type === 'task') {
        this.deleteTask(this.confirmDialog.groupIndex, this.confirmDialog.taskIndex)
      } else if (this.confirmDialog.type === 'group') {
        this.deleteGroup(this.confirmDialog.groupIndex)
      }
    },
    setTaskSelect: function (groupLevel, groupIndex, taskIndex) {
      this.$emit('updateTaskSelect', {
        groupLevel: groupLevel,
        groupIndex: groupIndex,
        taskIndex: taskIndex
      })
    },
    // mergeListGroup: function (processorLIndex, processorLLIndex, taskGroup) {
    //   this.processors.processors.L[processorLIndex].L[processorLLIndex].M.optionTemplate.M.processors.L = taskGroup
    //   this.$emit('update', this.processors.processors.L)
    // },
    handleTaskSelect: function (e) {
      this.$emit('taskSelect', e)
    },
    checkMove: function (e) {
      console.log(`Future index: ${e.draggedContext.futureIndex}`)
    },
    inputFn: function (val) {
      console.log(val)
    },
    emitter: function (val) {
      // console.log(val)
    }
  },
  created () {
  },
  mounted () {
  }
}
</script>
<style>
.current{
  background:#d6e2e9;
  /* background:var(listactive); */
}
</style>
