var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('draggable',_vm._b({on:{"input":_vm.emitter},model:{value:(_vm.processors.L),callback:function ($$v) {_vm.$set(_vm.processors, "L", $$v)},expression:"processors.L"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.processors && _vm.processors.L),function(processorL,processorLIndex){return _c('v-list-group',{key:processorLIndex,attrs:{"value":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Group "+_vm._s(processorLIndex + 1))])],1)]},proxy:true},{key:"appendIcon",fn:function(){return [_c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-content-click":_vm.closeOnContentClick},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-menu")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-group',{attrs:{"sub-group":"","value":true},on:{"click":function($event){_vm.closeOnContentClick = false}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Add New")]),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)]},proxy:true}],null,true)},_vm._l((_vm.endpointEnum.tasks),function(item,i){return _c('v-list-item',{key:i,staticClass:"ml-4",attrs:{"link":""},on:{"click":function($event){_vm.closeOnContentClick = true, _vm.addNewTask(processorLIndex, item)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)}),1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.confirmDialog = {
                isActive: true,
                type: 'group',
                groupIndex: processorLIndex,
                taskIndex: -1,
                name: ("Group " + (processorLIndex + 1))
              }, _vm.closeOnContentClick = true}}},[_c('v-list-item-title',[_vm._v("Delete")]),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete-circle")])],1)],1)],1)],1)]},proxy:true}],null,true)},[_c('draggable',_vm._b({on:{"input":_vm.emitter},model:{value:(processorL.L),callback:function ($$v) {_vm.$set(processorL, "L", $$v)},expression:"processorL.L"}},'draggable',_vm.dragOptions2,false),[_vm._l((processorL.L),function(processorLL,processorLLIndex){return [(processorLL.M.optionTemplate && processorLL.M.optionTemplate.M && processorLL.M.optionTemplate.M.processors)?[_c('v-list-group',{key:processorLLIndex,attrs:{"value":true,"sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',{on:{"click":function($event){$event.stopPropagation();_vm.$emit('taskSelect', processorLL.M), _vm.setTaskSelect(_vm.groupLevel, processorLIndex, processorLLIndex)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(processorLL.M.name.S.split(/\./)[1])}})],1)]},proxy:true},{key:"appendIcon",fn:function(){return [_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-vertical")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.addNewTaskProcessor(processorLIndex, processorLLIndex)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-plus-circle")])],1),_c('v-list-item-title',[_vm._v("Add Group")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.confirmDialog = {
                        isActive: true,
                        type: 'task',
                        groupIndex: processorLIndex,
                        taskIndex: processorLLIndex,
                        name: processorLL.M.name.S
                      }}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete-circle")])],1),_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)]},proxy:true}],null,true)},[_c('processor-list-menu',{staticClass:"ml-4",attrs:{"groupLevel":_vm.groupLevel + 1,"taskSelectIndexObj":_vm.taskSelectIndexObj},on:{"updateTaskSelect":function($event){return _vm.$emit('updateTaskSelect', $event)},"taskSelect":_vm.handleTaskSelect},model:{value:(processorLL.M.optionTemplate.M.processors),callback:function ($$v) {_vm.$set(processorLL.M.optionTemplate.M, "processors", $$v)},expression:"processorLL.M.optionTemplate.M.processors"}})],1)]:[_c('v-list-item',{key:processorLLIndex,class:_vm.groupLevel === _vm.taskSelectIndexObj.groupLevel
              && processorLIndex === _vm.taskSelectIndexObj.groupIndex
              && processorLLIndex === _vm.taskSelectIndexObj.taskIndex ? 'listactive' : null,attrs:{"dense":""},on:{"click":function($event){_vm.$emit('taskSelect', processorLL.M), _vm.setTaskSelect(_vm.groupLevel, processorLIndex, processorLLIndex)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var active = ref.active;
return [_c('v-list-item-content',{on:{"click":function($event){active ? $event.stopPropagation() : null}}},[_c('v-list-item-title',{staticClass:"ml-4",domProps:{"textContent":_vm._s(processorLL.M.name.S.split(/\./)[1])}})],1),_c('v-list-item-icon',[_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return (function () {
                    _vm.confirmDialog = {
                      isActive: true,
                      type: 'task',
                      groupIndex: processorLIndex,
                      taskIndex: processorLLIndex,
                      name: processorLL.M.name.S
                    }
                  })($event)}}},[_vm._v("mdi-delete-circle")])],1)]}}],null,true)})]]})],2)],1)}),1),_c('confirm-dialog',{attrs:{"type":"delete","title":_vm.confirmDialog.name},on:{"confirm":function($event){return _vm.confirmDeleteDialog()}},model:{value:(_vm.confirmDialog.isActive),callback:function ($$v) {_vm.$set(_vm.confirmDialog, "isActive", $$v)},expression:"confirmDialog.isActive"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }